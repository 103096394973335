import qs from 'qs';

import type { TagType } from '~/consts/tags';

import type { ScheduleLogsResponse } from '~/types/responses';

import { json_headers, request } from './common';

interface QueryParamsProps {
  page: number;
  limit: number;
  id_vendor?: string;
  id_opportunity_salesforce?: string;
}

interface QueryOffersParams {
  page?: number;
  limit?: number;
  queryString?: string;
  region?: string;
  filter?: string;
  type?: string;
  brand?: string;
  orderByScheduleDate?: number;
}

interface createOfferPackagesRequestBody {
  property_id: string;
  offer_id_salesforce_external: string;
  create_missing?: boolean;
}
export default class OffersService {
  static basePath() {
    return window.configs.API_HOST + '/api/offers';
  }

  static tagsPath() {
    return window.configs.API_HOST + '/api/offers/tags';
  }

  static contentPath() {
    return window.configs.API_HOST + '/api/offers-content';
  }

  static customOffersPath() {
    return window.configs.API_HOST + '/api/offers/custom';
  }

  static searchPath() {
    return window.configs.BOOKING_FLOW_V2 === 'true'
      ? window.configs.API_HOST + '/api/offers'
      : window.configs.API_HOST + '/api/offers-search';
  }
  static offerAddonsPath() {
    return window.configs.API_HOST + '/api/offers/addons';
  }
  static getPackagesBulkCreatePath() {
    return `${window.configs.API_HOST}/api/offers/packages/create-bulk`;
  }
  static vendorAddonsPath() {
    return window.configs.API_HOST + '/api/vendor-addons';
  }

  static getAllLocations() {
    return request(`${this.basePath()}/locations`, {
      method: 'GET',
      credentials: 'omit',
    });
  }

  static getTags(type) {
    return request(`${this.tagsPath()}/${type}`, { method: 'GET' });
  }

  static createTag(type, name) {
    return request(`${this.tagsPath()}/${type}`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify({ name }),
    });
  }

  static updateTag(type: TagType, id: number, name: string) {
    return request(`${this.tagsPath()}/${type}/${id}`, {
      method: 'PUT',
      headers: json_headers,
      body: JSON.stringify({ name }),
    });
  }

  static deleteTag(type: TagType, id: number) {
    return request(`${this.tagsPath()}/${type}/${id}`, {
      method: 'DELETE',
      headers: json_headers,
      ignoreResponse: true,
    });
  }

  // Custom offers
  static getCustomOffers({ customerId: customer_id, page = 1, perPage = 25 }) {
    const queryParams = {
      page,
      perPage,
      customer_id,
    };

    return request(`${this.customOffersPath()}?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getCustomOffer(offerId: string, options?: { include_payment_metadata?: boolean }) {
    return request(`${this.customOffersPath()}/${offerId}?${qs.stringify(options)}`, {
      method: 'GET',
    });
  }

  static createCustomOffer(payload) {
    return request(`${this.customOffersPath()}`, {
      method: 'POST',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static updateCustomOffer(offerId: string, payload) {
    return request(`${this.customOffersPath()}/${offerId}`, {
      method: 'PUT',
      headers: json_headers,
      body: JSON.stringify(payload),
    });
  }

  static deleteCustomOffer(offerId: string) {
    return request(`${this.customOffersPath()}/${offerId}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static setCustomOfferStatus(offerId: string, status: App.CustomOffer['status']) {
    return request(`${this.customOffersPath()}/${offerId}/status`, {
      method: 'PUT',
      headers: json_headers,
      body: JSON.stringify({ status }),
    });
  }

  // Offers
  static getOffers({
    page = 1,
    limit = 15,
    queryString,
    region,
    filter,
    brand,
    type,
    orderByScheduleDate,
  }: QueryOffersParams) {
    const queryParams = {
      page,
      limit,
      q: queryString,
      region,
      filter,
      type,
      brand,
      orderByScheduleDate,
    };

    return request(`${this.basePath()}?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static getFilterOffers({ page = 1, limit = 15, queryString, filter }) {
    let queryParams;

    if (filter) {
      queryParams = {
        page,
        limit,
        filter,
      };
    } else {
      queryParams = {
        page,
        limit,
      };
    }

    return request(`${this.basePath()}?${qs.stringify(queryParams)}&${queryString}`, {
      method: 'GET',
    });
  }

  static getAllAddons({ vendorId, addonOpportunityId, page = 1, limit = 30 }) {
    const queryParams: QueryParamsProps = { page, limit };

    if (vendorId) {
      queryParams.id_vendor = vendorId;
    }

    if (addonOpportunityId) {
      queryParams.id_opportunity_salesforce = addonOpportunityId;
    }

    return request(`${this.basePath()}/addons/all?${qs.stringify(queryParams)}`, { method: 'GET' });
  }

  static searchOffers({ queryString, brand }) {
    if (window.configs.BOOKING_FLOW_V2 !== 'true') {
      const queryParams = {
        q: queryString,
        brand,
      };

      return request(`${this.searchPath()}?${qs.stringify(queryParams)}`, {
        method: 'GET',
      });
    }

    if (!queryString.includes('brand=')) {
      queryString += `&brand=${brand}`;
    }

    return request(`${this.searchPath()}?${queryString}&limit=10`, {
      method: 'GET',
    });
  }

  static getOfferSchema(id) {
    return request(this.basePath() + '/' + id, { method: 'OPTIONS' });
  }

  static getOffer(id, options?) {
    let params = '';
    if (options) {
      params = Object.entries(options)
        .map(([key, val]: [string, string]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');
    }

    return (
      request(this.basePath() + `/${id}?${params}`, {
        method: 'GET',
      })
        .then((response) => {
          response.result.packages = response.result.packages.map((p) => ({
            ...p,
            addons: p.addons || [],
            unique_key: p.id_salesforce_external,
          }));
          return response;
        })
        // tests not playing nice
        .catch()
    );
  }

  static getOfferLocalization(offerId) {
    return request(this.basePath() + `/${offerId}/localization`, {
      method: 'GET',
    });
  }

  static getOfferTemplates() {
    return request(this.basePath() + '/templates/all', {
      method: 'GET',
    });
  }

  static upsertOfferTemplate(template: App.OfferTemplate) {
    //id is autoincremented integer starting at 1.
    //if no collision is found on id then
    //a new row will be created
    const id = template.id ? template.id : 0;
    const jsonData = JSON.stringify(template);
    return request(this.basePath() + '/templates/' + id, {
      method: 'PUT',
      body: jsonData,
      headers: json_headers,
    });
  }

  static deleteOfferTemplate(id: number) {
    return request(this.basePath() + '/templates/' + id, {
      method: 'DELETE',
    });
  }

  static getPackage(idOffer, id) {
    return request(`${this.basePath()}/${idOffer}/packages/${id}`, {
      method: 'GET',
    });
  }

  static deletePackage(idOffer, id) {
    return request(`${this.basePath()}/${idOffer}/packages/${id}`, {
      method: 'DELETE',
    });
  }

  static getAddon(idOffer, idPackage, idAddon) {
    return request(`${this.basePath()}/${idOffer}/packages/${idPackage}/addons/${idAddon}`, {
      method: 'GET',
    });
  }

  static createOffer(object) {
    const jsonData = JSON.stringify(object);
    return request(this.basePath(), {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateOffer(object, id) {
    const jsonData = JSON.stringify(object);
    return request(this.basePath() + '/' + id, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static async updateOfferContentLocalization(offerId, fields) {
    const data = JSON.stringify({ localizationStrings: fields });
    const basePath = this.basePath();

    return request(`${basePath}/${offerId}/localization`, {
      method: 'PUT',
      headers: json_headers,
      body: data,
    });
  }

  static schedulesUri(idOffer) {
    return `${this.basePath()}/${idOffer}/schedules`;
  }

  static scheduleDataMigrationUri(idOffer) {
    return `${this.basePath()}/${idOffer}/scheduleDataMigration`;
  }

  static updateSchedule(object, idOffer, id) {
    const jsonData = JSON.stringify(object);
    return request(`${this.schedulesUri(idOffer)}/${id}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static migrateScheduleData(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(`${this.scheduleDataMigrationUri(idOffer)}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateScheduleList(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(`${this.schedulesUri(idOffer)}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createScheduleList(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(`${this.schedulesUri(idOffer)}`, {
      method: 'PUT',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createSchedule(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(`${this.schedulesUri(idOffer)}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteSchedule(idOffer, id) {
    return request(`${this.schedulesUri(idOffer)}/${id}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static deleteScheduleList(idOffer, object) {
    const jsonData = JSON.stringify(object);
    return request(`${this.schedulesUri(idOffer)}`, {
      method: 'DELETE',
      ignoreResponse: true,
      headers: json_headers,
      body: jsonData,
    });
  }

  static getSchedules(idOffer) {
    return request(this.schedulesUri(idOffer), { method: 'GET' });
  }

  static getScheduleSchema(idOffer) {
    return request(this.schedulesUri(idOffer), { method: 'OPTIONS' });
  }

  static getScheduleLogs(
    idOffer: string,
    queryParams: { type?: string; brand?: string },
  ): Promise<ScheduleLogsResponse> {
    return request(`${this.basePath()}/${idOffer}/schedule-logs?${qs.stringify(queryParams)}`, {
      method: 'GET',
    });
  }

  static imagesUri(idOffer) {
    return `${this.basePath()}/${idOffer}/images`;
  }

  static imageUri(idOffer, idImage) {
    return `${this.basePath()}/${idOffer}/images/${idImage}`;
  }

  static getImages(idOffer) {
    return request(this.imagesUri(idOffer), { method: 'GET' });
  }

  static createImage(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(this.imagesUri(idOffer), {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateImage(idOffer, idImage, data) {
    const jsonData = JSON.stringify(data);
    return request(this.imageUri(idOffer, idImage), {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateImages(objects, idOffer) {
    const jsonData = JSON.stringify(objects);
    return request(this.imagesUri(idOffer), {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteImage(idOffer, id) {
    return request(`${this.imagesUri(idOffer)}/${id}`, { method: 'DELETE' });
  }

  static getVendorAddons(vendorId) {
    return request(`${this.vendorAddonsPath()}/${vendorId}`, { method: 'GET' });
  }

  static getVendorAddon(vendorId, addonId) {
    return request(`${this.vendorAddonsPath()}/${vendorId}/opportunities/${addonId}`, { method: 'GET' });
  }

  static getVendorAddonOption(vendorId, addonId, addonOptionId) {
    return request(`${this.vendorAddonsPath()}/${vendorId}/opportunities/${addonId}/deal-options/${addonOptionId}`, {
      method: 'GET',
    });
  }

  static getVendorAddonOptions({ vendorId, addonOpportunityId, page = 1, limit = 30 }) {
    const queryParams = { page, limit };

    return request(
      `${this.vendorAddonsPath()}/${vendorId}/opportunities/${addonOpportunityId}/deal-options?${qs.stringify(
        queryParams,
      )}`,
      { method: 'GET' },
    );
  }

  static addonImagesUri(addonSalesforceId) {
    return `${this.offerAddonsPath()}/${addonSalesforceId}/images`;
  }

  static addonImageUri(addonSalesforceId, imageId) {
    return `${this.offerAddonsPath()}/${addonSalesforceId}/images/${imageId}`;
  }

  static getAddonImages(addonSalesforceId) {
    return request(this.addonImagesUri(addonSalesforceId), { method: 'GET' });
  }

  static createAddonImage(addonSalesforceId, data) {
    const jsonData = JSON.stringify(data);
    return request(this.addonImagesUri(addonSalesforceId), {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateAddonImage(addonSalesforceId, imageId, data) {
    const jsonData = JSON.stringify(data);
    return request(this.addonImageUri(addonSalesforceId, imageId), {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateAddonImages(addonSalesforceId, data) {
    const jsonData = JSON.stringify(data);
    return request(this.addonImagesUri(addonSalesforceId), {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static deleteAddonImage(addonSalesforceId, imageId) {
    return request(`${this.addonImageUri(addonSalesforceId, imageId)}`, {
      method: 'DELETE',
      ignoreResponse: true,
    });
  }

  static roomPoliciesUri(idOffer) {
    return `${this.basePath()}/${idOffer}/room-policies`;
  }

  static getRoomPolicySchema(idOffer) {
    return request(this.roomPoliciesUri(idOffer), { method: 'OPTIONS' });
  }

  static createRoomPolicy(object, idOffer) {
    const jsonData = JSON.stringify(object);
    return request(`${this.roomPoliciesUri(idOffer)}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static updateRoomPolicy(object, idOffer, idRoomPolicy) {
    const jsonData = JSON.stringify(object);
    return request(`${this.roomPoliciesUri(idOffer)}/${idRoomPolicy}`, {
      method: 'PATCH',
      headers: json_headers,
      body: jsonData,
    });
  }

  static createOfferPackages(object: createOfferPackagesRequestBody) {
    const jsonData = JSON.stringify(object);
    return request(`${this.getPackagesBulkCreatePath()}`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  /**
   * Primarily used with LuxLoyalty to map package upgrades. This then enables
   * complementary upgrades for members.
   */
  static mapPackageUpgrades(idOffer: string) {
    return request(`${this.basePath()}/${idOffer}/map-package-upgrades`, {
      method: 'POST',
      headers: json_headers,
    });
  }

  static createOfferDeepLink(offerId: string, params: { [key: string]: any }) {
    const jsonData = JSON.stringify(params);
    return request(`${this.basePath()}/${offerId}/deep-links`, {
      method: 'POST',
      headers: json_headers,
      body: jsonData,
    });
  }

  static getOffersDeepLinks() {
    return request(`${this.basePath()}/deep-links/all`, {
      method: 'GET',
    });
  }
}

import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';

import { Box, Divider, Typography } from '@mui/material';

import OffersService from '~/services/OffersService';
import PublicOffersService from '~/services/PublicOfferService';
import UsersService from '~/services/UsersService';

import OfferDeepLinksPage from './OfferDeepLinksPage';

const BRAND = 'luxuryescapes';

function OfferDeepLinksContainer() {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [deepLinksList, setDeepLinksList] = useState([]);

  const decorateDeepLinks = async (deepLinks: Array<any>) => {
    const userIds = new Set();
    const offerIds = new Set();

    if (!deepLinks.length) {
      return [];
    }

    deepLinks.forEach((deepLink) => {
      if (deepLink.customerId) {
        userIds.add(deepLink.customerId);
      }
      if (deepLink.offerId) {
        offerIds.add(deepLink.offerId);
      }
    });

    const [userSummaries, offers] = await Promise.all([
      UsersService.getUsersSummaryByIds(Array.from(userIds), BRAND),
      PublicOffersService.getMultipleOffersById(Array.from(offerIds) as string[], 'AU', BRAND),
    ]);

    const offersMap = new Map(offers.map((offer) => [offer.id_salesforce_external, offer]));

    deepLinks.forEach((deepLink) => {
      if (deepLink.customerId && userSummaries.has(deepLink.customerId)) {
        deepLink.customerEmail = userSummaries.get(deepLink.customerId).email;
      }
      if (deepLink.offerId) {
        deepLink.offerName = offersMap.get(deepLink.offerId).name || 'Offer has ended';
        deepLink.offerSlug = offersMap.get(deepLink.offerId).slug;
      }
    });

    return deepLinks;
  };

  const fetchDeepLinks = useCallback(async () => {
    setLoading(true);

    try {
      const response = await OffersService.getOffersDeepLinks();

      if (response.success) {
        const deepLinks = await decorateDeepLinks(response.result);
        setDeepLinksList(deepLinks);
      } else {
        setErrors('Failed to fetch deep links');
      }
    } catch (e) {
      setErrors(`Failed fetching deep links: ${e?.message}`);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDeepLinks();
  }, [fetchDeepLinks]);

  return (
    <Box>
      <Helmet>
        <title>Offer Deep Links</title>
      </Helmet>
      <Typography component="h4" variant="h4" mt={5}>
        Offer Deep Links
      </Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
      <OfferDeepLinksPage deepLinksList={deepLinksList} loading={loading} errors={errors} />
    </Box>
  );
}

export default OfferDeepLinksContainer;

import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';

import { Offer } from '@luxuryescapes/contract-svc-offer';
import * as libRegions from '@luxuryescapes/lib-regions';

import PageHeader from '~/components/Common/Elements/PageHeader';
import OfferSearchForm from '~/components/Common/Forms/OfferSearchForm';
import OfferList from '~/components/Common/OfferListContainer';

import useCurrentUser from '~/hooks/useCurrentUser';

import OffersService from '~/services/OffersService';
import { customerPortalHost } from '~/services/common';

const STEPS = {
  SELECT_DATES: { index: 0, label: 'Select Dates' },
  SEARCH_OFFER: { index: 1, label: 'Search Offer' },
  REVIEW: { index: 2, label: 'Review & Submit' },
  SUCCESS: { index: 3, label: 'Success' },
};

const stepLabels = Object.values(STEPS)
  .sort((a, b) => a.index - b.index)
  .map((step) => step.label);

type FormData = {
  startDate: Date;
  endDate: Date;
  region: string;
  offerId: string;
  approverId: string;
  caseNumber: string;
  notes: string;
};

export default function CreateOfferDeepLinkForm() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [searchString, setSearchString] = useState('');
  const [selectedOffer, setSelectedOffer] = useState<App.Offer | null>(null);
  const [deepLinkResponse, setDeepLinkResponse] = useState<Offer.OfferDeepLink>(null);

  const history = useHistory();
  const { id_user } = useParams<{ id_user: string }>();
  const { user: currentUser } = useCurrentUser();
  const tenant = useSelector((state: App.State) => state.tenant);
  const regions = libRegions.getRegions(tenant.brand);

  const { control, handleSubmit, watch, setValue, trigger } = useForm<FormData>({
    defaultValues: {
      region: '',
      offerId: '',
      approverId: '',
      caseNumber: '',
      notes: '',
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleNext = async () => {
    let isValid = true;

    if (activeStep === STEPS.SELECT_DATES.index) {
      isValid = await trigger(['region', 'approverId', 'caseNumber']);
    }

    if (isValid) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleOfferSearch = (query: string) => {
    setSearchString(`q=${query}`);
  };

  const handleOfferSelect = (offer: App.Offer) => {
    setSelectedOffer(offer);
    setValue('offerId', offer.id_salesforce_external);
    handleNext();
  };

  const customerPortalOfferLink = useMemo(() => {
    if (selectedOffer) {
      const offerLink = `/offer/${selectedOffer.slug}/${selectedOffer.id_salesforce_external}`;

      return `${customerPortalHost(tenant)}${offerLink}`;
    }
    return '';
  }, [selectedOffer, tenant]);

  const onSubmit = useCallback(
    async (data: FormData) => {
      const payload = {
        fk_offer_id: data.offerId,
        region: data.region,
        fk_customer_id: id_user,
        agent_email: currentUser.email,
        approver_id: data.approverId,
        case_number: data.caseNumber,
        notes: data.notes,
      };

      try {
        const response = await OffersService.createOfferDeepLink(data.offerId, payload);

        if (response.success) {
          setDeepLinkResponse(response.result);

          navigator.clipboard
            .writeText(`${customerPortalOfferLink}?offerDeepLinkHash=${response.result.hash}`)
            .then(() => {
              enqueueSnackbar('Deep link hash copied to clipboard!', { variant: 'success' });
            })
            .catch(() => {
              enqueueSnackbar('Failed to copy hash to clipboard', { variant: 'error' });
            });
          setActiveStep(STEPS.SUCCESS.index);
        } else {
          enqueueSnackbar('Failed to create offer deep link', { variant: 'error' });
        }
      } catch (e) {
        console.error('Error creating deep link:', e);
        enqueueSnackbar('Error creating offer deep link: ' + (e.message || 'Unknown error'), { variant: 'error' });
      }
    },
    [enqueueSnackbar, id_user, currentUser.email, customerPortalOfferLink],
  );

  const handleStepClick = (stepIndex: number) => {
    if (stepIndex <= activeStep) {
      setActiveStep(stepIndex);
    }
  };

  const isLastStep = activeStep === STEPS.REVIEW.index;

  const renderStepContent = (step: number) => {
    switch (step) {
      case STEPS.SELECT_DATES.index:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Controller
                name="region"
                control={control}
                rules={{ required: 'Region is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    label="Region"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    error={!!error}
                    helperText={error?.message}
                  >
                    {regions.map((region) => (
                      <MenuItem key={region.code} value={region.code}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="approverId"
                control={control}
                rules={{ required: 'Approver ID is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Approver ID"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    required
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="caseNumber"
                control={control}
                rules={{ required: 'Case Number is required' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Case Number"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    required
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name="notes"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    label="Notes"
                    value={field.value}
                    onChange={field.onChange}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        );

      case STEPS.SEARCH_OFFER.index:
        return (
          <Box>
            <OfferSearchForm destinations={[]} searchQuery={handleOfferSearch} minimal />
            <OfferList searchString={searchString} onRowClick={handleOfferSelect} showCountMessage />
          </Box>
        );

      case STEPS.REVIEW.index:
        return (
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom align="center">
              Review Deep Link Details
            </Typography>

            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Schedule Details
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Region:
                        </Typography>
                        <Typography variant="body2">
                          {regions.find((r) => r.code === watch('region'))?.name || watch('region')}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                            Offer:
                          </Typography>
                          <Typography variant="body2">{selectedOffer?.name || 'Not selected'}</Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Approver ID:
                        </Typography>
                        <Typography variant="body2">{watch('approverId')}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Case Number:
                        </Typography>
                        <Typography variant="body2">{watch('caseNumber')}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Notes:
                        </Typography>
                        <Typography variant="body2">{watch('notes')}</Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Customer Information
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Customer ID:
                        </Typography>
                        <Typography variant="body2">{id_user}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Agent Email:
                        </Typography>
                        <Typography variant="body2">{currentUser.email}</Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        );

      case STEPS.SUCCESS.index:
        return (
          <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom align="center">
              Deep Link Created Successfully!
            </Typography>

            <Box sx={{ mt: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Deep Link Details
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Offer ID:
                        </Typography>
                        <Typography variant="body2">{deepLinkResponse?.offerId}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Region:
                        </Typography>
                        <Typography variant="body2">
                          {regions.find((r) => r.code === deepLinkResponse?.region)?.name || deepLinkResponse?.region}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Status:
                        </Typography>
                        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                          {deepLinkResponse?.status}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Agent email:
                        </Typography>
                        <Typography variant="body2">{deepLinkResponse?.agentEmail}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Approver ID:
                        </Typography>
                        <Typography variant="body2">{deepLinkResponse?.approverId}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Case Number:
                        </Typography>
                        <Typography variant="body2">{deepLinkResponse?.caseNumber}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', width: '120px' }}>
                          Notes:
                        </Typography>
                        <Typography variant="body2">{deepLinkResponse?.notes}</Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                      Deep Link Hash
                    </Typography>
                    <Paper
                      variant="outlined"
                      sx={{
                        p: 2,
                        bgcolor: 'grey.100',
                        fontFamily: 'monospace',
                        wordBreak: 'break-all',
                        maxHeight: '150px',
                        overflow: 'auto',
                      }}
                    >
                      {deepLinkResponse?.hash}
                    </Paper>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(deepLinkResponse?.hash);
                          enqueueSnackbar('Hash copied to clipboard!', { variant: 'success' });
                        }}
                      >
                        Copy Hash
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" onClick={() => history.push(`/users/${id_user}`)}>
                Return to User Profile
              </Button>
            </Box>
          </Paper>
        );

      default:
        return null;
    }
  };

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Book Offer</title>
      </Helmet>

      <PageHeader title="Book Offer" />

      <Box sx={{ width: '100%', mt: 4 }}>
        <Stepper activeStep={activeStep}>
          {stepLabels.map((label, index) => (
            <Step
              key={label}
              completed={index < activeStep}
              onClick={() => handleStepClick(index)}
              sx={{
                cursor: index <= activeStep || index === activeStep + 1 ? 'pointer' : 'default',
                '& .MuiStepLabel-root': {
                  cursor: index <= activeStep || index === activeStep + 1 ? 'pointer' : 'default',
                },
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: 4 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {renderStepContent(activeStep)}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              {activeStep > 0 && !isLastStep && (
                <Button onClick={handleBack} sx={{ mr: 1 }}>
                  Back
                </Button>
              )}
              {!isLastStep ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={!watch('offerId') && activeStep === STEPS.SEARCH_OFFER.index}
                >
                  Next
                </Button>
              ) : (
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Container>
  );
}

import React from 'react';

import { useSelector } from 'react-redux';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Alert, Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { Offer } from '@luxuryescapes/contract-svc-offer';

import { formatDateSlashes, sortByDate } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

interface DeepLink extends Offer.OfferDeepLink {
  customerEmail?: string;
  offerName?: string;
  offerSlug?: string;
}

interface OfferDeepLinksPageProps {
  deepLinksList: Array<DeepLink>;
  loading: boolean;
  errors: string | null;
}

function OfferDeepLinksPage({ deepLinksList, loading, errors }: OfferDeepLinksPageProps) {
  const tenant = useSelector((state: App.State) => state.tenant);

  const handleCopyDeepLink = (deepLink: DeepLink) => {
    if (deepLink.hash) {
      const offerLink = `/offer/${deepLink.offerSlug}/${deepLink.offerId}`;
      const baseUrl = customerPortalHost(tenant);
      const fullUrl = `${baseUrl}${offerLink}?offerDeepLinkHash=${deepLink.hash}`;

      navigator.clipboard
        .writeText(fullUrl)
        .then(() => {
          // You could add a snackbar notification here if needed
        })
        .catch((err) => {
          console.error('Failed to copy deep link:', err);
        });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'copyDeepLink',
      headerName: 'Copy',
      minWidth: 60,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltip title="Copy Deep Link">
          <IconButton size="small" onClick={() => handleCopyDeepLink(params.row)} disabled={!params.row.hash}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: 'offerId',
      headerName: 'Offer ID',
      minWidth: 175,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link href={`/offers/${params.row.offerId}`} target="_blank">
          {params.row.offerId}
        </Link>
      ),
    },
    {
      field: 'offerName',
      headerName: 'Offer Name',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'region',
      headerName: 'Region',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'customerEmail',
      headerName: 'Customer Email',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Link href={`/users/${params.row.customerId}`} target="_blank">
          {params.row.customerEmail}
        </Link>
      ),
    },
    {
      field: 'agentEmail',
      headerName: 'Agent Email',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'details',
      headerName: 'Details',
      minWidth: 300,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Stack spacing={1}>
            <Typography variant="body2">
              <Typography component="span" fontWeight="bold">
                Approver ID:
              </Typography>{' '}
              {params.row.approverId}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" fontWeight="bold">
                Case Number:
              </Typography>{' '}
              {params.row.caseNumber}
            </Typography>
            <Typography variant="body2">
              <Typography component="span" fontWeight="bold">
                Notes:
              </Typography>{' '}
              {params.row.notes}
            </Typography>
          </Stack>
        </Box>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      minWidth: 120,
      flex: 1,
      type: 'date',
      sortComparator: sortByDate,
      valueFormatter: (value) => formatDateSlashes(value),
    },
  ];

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      {errors && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errors}
        </Alert>
      )}
      <DataGrid
        rows={deepLinksList}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
        pageSizeOptions={[10, 25, 50]}
        disableRowSelectionOnClick
        loading={loading}
        getRowId={(row) => row.offerId + row.customerId + row.createdAt}
        autoHeight
      />
    </Box>
  );
}

export default OfferDeepLinksPage;
